import { createGlobalStyle } from 'styled-components';

import { colors } from 'src/theme/colors';

export default createGlobalStyle`
  body {
    background-color: ${colors.white};
    color: ${colors.black};
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.6;
    overflow-x: hidden;
    position: relative;
  }
`;
